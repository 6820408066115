html {
  background-color: rgba(0,32,97,1);
  color: white;
  height: 100%;
  background-attachment: fixed;
}

body, #root, main {
  height: 100%;
}

body {
  line-height:1.3;
  background: linear-gradient(0deg,rgba(0, 32, 97, 1) 0%,rgba(0,32,97,1) 85%, rgba(39, 81, 165, 1) 100%);
}

main {
  position:relative;
}

h1 {
  font-weight: 600;
  font-size: 135%;
  margin-bottom: 4px;
}

h3 {
  font-weight: 600;
  font-size: 100%;
}

#logo {
  position:absolute;
  z-index:99;
}

#logo, #logo img {
  height:85px;
  width:auto;
}
button {
  user-select: none;
}
small.unit {
  font-weight: normal;
  font-size: 85%;
}
nav {
  border-bottom: 1px solid #17c2c7;
  height:85px;
  background-color: #001a4f;
  user-select: none;
}

nav ul {
  list-style-type: none;
  display: block;
  text-align:right;
  height: 85px;
  z-index: 100;
  position: relative;
  white-space: pre;
}

nav li {
  height:85px;
  display: inline-block;
}
nav li a {
  text-decoration: none;
  color: white;
  font-size: 127%;
  padding: 15px 15px 10px;
  margin-top: 0px;
  display: inline-block;
  height: 29px;
  vertical-align: middle;
  margin: 28px 10px 0;
  background-color: #0a2c71;
  border-radius: 7px 7px 0 0;
}
nav li a:hover,
nav li a.active {
  text-decoration: none;
  border-bottom: 3px solid #17c2c7;
}
nav li a.active {
  background-color: #0f388a;
}

#dashboard {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.dash-widget {
  max-width: 202px;
  margin: 10px 6px;
  border: 1px solid #1b9195;
  border-radius: 4px;
  padding: 8px 0 0;
  text-align: center;
  height: 254px;
  position: relative;
}

#device-full {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

#device-info {
  border-left: 3px solid #1b9195;
  padding-left: 9px;
  margin: 2px 0 10px 6px;
  position: relative;
  max-width: 402px;
}

#device-info div {
  font-size: 92%;
}

#device-info .online, #device-info .offline {
  margin-left: 9px;
  margin-bottom: 5px;
}

.device {
  display: flex;
  border: 1px solid #17c2c7;
  margin-bottom: 10px;
    border-radius: 3px;
    background-color: #080875;
    padding: 5px;
    min-height: 76px;
    flex-wrap: wrap;
}

.device-widget {
  max-width: 75px;
  margin-right: 22px;
  position:relative;
}

.device a {
  color: white;
  text-decoration: none;
}

/* .device .aqi-gauge-pm-value {
  display: none;
} */

.device .gauge-top {
  text-align: center;
  font-size: 90%;
  height:auto;
}

/* .device .gauge-container {
  width: 118px;
  left: -13px;
} */

.device .gauge-value {
  font-size:90%;
}

.device .gauge-value-2 {
  display: none;
}

.device .aqi-gauge-unit {
  display: none;
}

.device .aqi-gauge-value {
  font-size: 117%;
  font-weight: bold;
}

.device-name {
  width: 260px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.device-name h3 {
  font-size: 116%;
  margin-right: 20px;
}

.device .event-widget {
  font-size: 100%;
  max-width: 50%;
}

/* .recent-alerts span {
  border-bottom:1px solid #ddd
} */

.recent-alerts .highlight {
  color: rgb(255, 255, 169);
}

#message-bar {
  bottom: 0;
  left: 0;
  position: fixed;
  font-size: 95%;
  width:100%;
  overflow: hidden;
  background-color: #0f388a;
  color: #FFF;
  border-top: 1px solid white;
  white-space:nowrap;
}

#message-bar-inner {
    padding:4px;
}

#message-bar-right {
  position: absolute;
  top:1px;
  right:3px;
}

#settings {
  float:right;
  margin: 20px;
}

#settings h3 {
  margin-bottom: 1px;
}

#settings select {
  margin-bottom: 7px;
}

.aqi-bar-chart {
  max-width:300px;
  max-height:100px;
}

.histogram-bar-chart {
  max-width:300px;
}

.gauge-container {
  position: relative;
  max-width: 112px;
  margin: 0px auto;
}

.device-widget .gauge-container {
  height: 75px;
  width: 75px;
}

.gauge-top {
  height: 48px;
}

.gauge-aqi-wrapper, .gauge-value-wrapper {
  position: absolute;
  top: 39%;
  text-align: center;
  width: 100%;
}

#temp .gauge-value-wrapper {
  top: 32%;
  line-height: 120%;
}

.gauge-value-align {
  max-width: 80px;
  margin: 0 auto;
}

.gauge-value {
  vertical-align: middle;
}

#histogram, #pm-100, #pm-25, #pm-10 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#histogram h3 {
  margin-bottom: 30px;
}

#histogram-labels {
  display: flex;
  font-size: 60%;
  padding: 3px;
  justify-content: space-between;
}

#events {
  width: 100%;
  height: auto;
  max-height: 255px;
  overflow-y: scroll;
  max-width: 1200px;
}

#events strong {
  font-weight: 600;
  font-size:110%;
}

#events ul {
  padding: 10px;
}

#events ul li {
  margin-bottom:5px;
  text-align:left;
  font-size: 90%;
}

.analysis-wrapper {
  max-width:1200px;
  width:100%;
  margin-bottom: 7px;
}

.analysis-chart-wrapper {
  height:75vh;
  width:100%;
}

#analysis-options select {
  margin-left: 10px;
}

#change-device {
  /* position: fixed;
  bottom: 19px;
  right: 0px;
  padding: 10px; */
}
#change-device select {
 font-size:110%;
 margin-left:5px;
}

.select-time {
  font-size: 90%;
  width:100%;
}
.select-time span {
  margin-left: 5px;
  text-decoration: underline;
  cursor: pointer;
}

.time-range {
  font-size: 90%;
  width: 100%;
  margin-bottom: 2px;
}

.time-range span {
  font-weight: bold;
}

.time-span.active {
  font-weight: bold;
}

.pagers {
  display:inline-block;
  margin-left:20px;
}

.pager {
  padding: 1px 10px;
}

#route-wrapper {
  padding: 10px 10px 50px;
}

.pause, .resume {
  margin-left:15px;
}

.offline {
  width:9px;
  height:9px;
  display: inline-block;
  margin-right: 4px;
  border-radius: 5px;
  border:1px solid #880000;
  background-color:#bf0000;
  box-shadow: 1px 1px 3px #CC3333;
}

.online {
  width:9px;
  height:9px;
  display: inline-block;
  margin-right: 4px;
  border-radius: 5px;
  border:1px solid #008800;
  background-color:#5ef35e;
  box-shadow: 1px 1px 3px #33CC33;
}

.air-quality {
  font-weight: bold;
}

.good-text {
  color: #7af26f;
}

.moderate-text {
  color: #eceb7c;
}

.unhealthy-sg-text {
  color: #eccb7c;
}

.unhealthy-text {
  color: #FF8585;
}

.very-unhealthy-text {
  color: #FF8585;
}

.hazardous-text {
  color: #F28FC7;
}

[data-tooltip] {
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  position: absolute;
  top: 0;
  right: 0;
  height: 16px;
  width: 16px;
  background-color: #a2a2a2;
  text-align: center;
  border-radius: 8px;
  color: #222;
  font-weight: bold;
  font-size: 95%;
  z-index: 9999;
}

[data-tooltip]::after {
  background-color: #cecece;
  border-radius: 0.3rem;
  color: #222;
  content: attr(data-tooltip);
  font-size: 1rem;
  font-size: 90%;
  font-weight: normal;
  line-height: 1.15rem;
  opacity: 0;
  padding: 0.25rem 0.5rem;
  position: absolute;
  text-align: center;
  text-transform: none;
  transition: opacity 0.2s;
  visibility: hidden;
  /* white-space: nowrap; */
  z-index: 1;
  min-width: 300px;
  pointer-events: none;
}

[data-tooltip].tooltip-top::before {
  border-style: solid;
  border-width: 0.3rem;
  content: "";
  opacity: 0;
  position: absolute;
  transition: opacity 0.2s;
  visibility: hidden;
  border-color: #cecece transparent transparent transparent;
  top: 0;
  left: 50%;
  margin-left: -0.3rem;
}

[data-tooltip].tooltip-top::after {
  bottom: 100%;
  left: 50%;
  transform: translate(-50%);
}

[data-tooltip].tooltip-bottom::before {
  border-style: solid;
  border-width: 0.3rem;
  content: "";
  opacity: 0;
  position: absolute;
  transition: opacity 0.2s;
  visibility: hidden;
  border-color: transparent transparent #cecece transparent;
  bottom: 0;
  left: 50%;
  margin-left: -0.3rem;
}

[data-tooltip].tooltip-bottom::after {
  top: 100%;
  left: 50%;
  transform: translate(-50%);
}

[data-tooltip].tooltip-left::before {
  border-style: solid;
  border-width: 0.3rem;
  content: "";
  opacity: 0;
  position: absolute;
  transition: opacity 0.2s;
  visibility: hidden;
  border-color: transparent transparent transparent #cecece;
  top: 0.3rem;
  right: calc(110% - 0.3rem);
  margin-top: -0.3rem;
}

[data-tooltip].tooltip-left::after {
  top: -0.3rem;
  right: calc(110% + 0.3rem);
}

[data-tooltip].tooltip-right::before {
  border-style: solid;
  border-width: 0.3rem;
  content: "";
  opacity: 0;
  position: absolute;
  transition: opacity 0.2s;
  visibility: hidden;
  border-color: transparent #cecece transparent transparent;
  top: 50%;
  top: 0.3rem;
  left: calc(110% - 0.3rem);
  margin-top: -0.3rem;
}

[data-tooltip].tooltip-right::after {
  top: -0.3rem;
  left: calc(110% + 0.3rem);
}

@media (max-width: 767px) {
  [data-tooltip].tooltip-mobile::before {
    display: none;
  }
  [data-tooltip].tooltip-mobile:after {
    font-size: 1rem;
    max-width: 20rem;
    position: fixed;
    bottom: auto;
    top: 50%;
    left: 50%;
    text-align: left;
    transform: translate(-50%);
    white-space: normal;
    min-width: 100px;
  }
}

[data-tooltip]:hover::after, [data-tooltip][class*=tooltip-]:hover::before {
  visibility: visible;
  opacity: 1;
}

.dash-widget [data-tooltip] {
  margin: 5px;
}

.tooltip-widget {
  right: -12px;
}

.event-widget .tooltip-widget {
  right: -21px;
}

.tooltip-analysis {
  position: relative;
  height: 16px;
  width: 100%;
  max-width: 1200px;
}

@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {
  nav li a {
    font-size: 105%;
    padding: 9px 13px 0px;
    height: 39px;
    margin: 35px 9px 0;
  }
}
@media (max-width: 767.98px) {}
@media (max-width: 600px) {
  #logo, #logo img, nav, nav ul, nav li {
    height: 60px;
  }
  nav li a {
    font-size: 105%;
    padding: 3px 5px 0px;
    height: 28px;
    margin: 27px 3px 0;
  }
  .device-name {
    width: 100%;
    margin-bottom: 5px;
  }
  .device-widget {
    /* width: 50%; */
    max-width: none;
  }
  .device .gauge-container {
    /* width: 123px; */
    left: 0px;
    margin: 0 auto;
  }
  .gauge-top h3 {
    padding: 3px 0;
  }
  .device .event-widget {
    max-width: 100%;
    width: 100%;
    margin-top: 5px;
  }
}